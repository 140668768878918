import React, { useContext } from 'react';

import { SelectedOptionsContext } from '../SelectedOptionsContext';
import SelectComponent from './SelectComponent';
import { crops } from '../data/micro_climate_data.json';

function SelectCropComponent(props) {
  const { onSelectCrop, selectedCrop } = useContext(SelectedOptionsContext);

  const onChangeCrop = (e) => {
    onSelectCrop(e.target.value);
  };

  return (
    <SelectComponent
        onChange={onChangeCrop}
        optionData={crops}
        selectedOption={selectedCrop} />
  );
}

export default SelectCropComponent;
