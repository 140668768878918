import React, {Fragment,} from 'react';
import styled from 'styled-components';

import TableLinkComponent from './components/TableLinkComponent'
import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage";
import SigunCountPage from "./pages/SigunCountPage";

const OuterBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 1rem;
  margin: 0;
  height: 100%;
  width: 100%;
  background: #94d3ac;
  position: fixed;
  overflow: hidden;
`;
const InnerBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  //padding: 0 1rem 1rem 1rem;
  padding: 1rem;
  height: 100%;
  width: 100%;
  background: white;
  border-radius: 0.5rem;
  font-size: 32px;
  line-height: 32px;
  
  @media only screen and (min-height: 200px) {
    // 아이폰미니 가로화면
    font-size: 11px;
    line-height: 11px;
    /* color: red; */
  }
  @media only screen and (min-height: 300px) {
    font-size: 15px;
    line-height: 15px;
    /* color: orange; */
  }
  @media only screen and (min-height: 450px) {
    // 아이폰미니세로화면
    font-size: 16px;
    line-height: 16px;
    /* color: yellow; */
  }
  @media only screen and (min-height: 650px) {
    // 아이패드미니 가로화면
    font-size: 18px;
    line-height: 18px;
    /* color: green; */
  }
  @media only screen and (min-height: 800px) {
    // 아이패드미니 세로화면
    font-size: 28px;
    line-height: 28px;
    /* color: blue; */
  }
  @media only screen and (min-height: 990px) {
    // 데스크탑
    font-size: 36px;
    line-height: 36px;
    /* color: violet; */
  }
`;

const App = () => {
    return (
        <Fragment>
            <BrowserRouter>
                <OuterBox>
                    <InnerBox>
                        <TableLinkComponent/>
                        <Routes>
                            <Route path="/" element={<HomePage/>}/>
                            <Route path={"/siguncount"} element={<SigunCountPage/>}/>
                        </Routes>
                    </InnerBox>
                </OuterBox>
            </BrowserRouter>
        </Fragment>
    );
}

export default App;
