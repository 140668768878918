import React from 'react';
import styled from 'styled-components';

const Select = styled.select`
  box-sizing: border-box;
  height: 1.7rem;
  width: 100%;
  margin: 0;
  padding: 0.2rem;
  background-color: #effcef;
  border: solid 0.1rem #94d3ac;
  border-radius: 0.5rem;
  cursor: pointer;
  color: #655c56;
  font-weight: bold;
  text-align-last: center;
  margin: 0 auto;
  text-align: center;
  transition: 0.5s ease;
  &:hover {
    background-color: #94d3ac;
    color: white;
    font-weight: bold;
  }
  &:focus {
    outline: none;
  }
`;

const Option = styled.option`
  box-sizing: border-box;
  background: white;
  height: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  color: #655c56;
  &:hover {
    cursor: pointer;
    color: red;
  }
  &:focus {
    outline: none;
  }
`;

const SelectComponent = props => {
  const { onChange, optionData, selectedOption } = props;
  return (
    <Select
      onChange={e => {
        onChange(e);
      }}
      value={selectedOption.id}
    >
      {optionData.map(item => (
        <Option
          key={item.id}
          value={item.id}
        >
          {item.icon} {item.name_kor} {item.type ? `(${item.type.type_kor})` : ''}
        </Option>
      ))}
    </Select>
  );
};

export default SelectComponent;
