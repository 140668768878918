import React, {useContext, useEffect, } from 'react';

import {SelectedOptionsContext} from '../SelectedOptionsContext';
import notFoundMapImage from "../image/notFoundMap.png";
import ProgressiveImage from "react-progressive-graceful-image";

const MapImageContainer = (props) => {
    const { onIsLoading, selectedRiskImgUrl } = useContext(SelectedOptionsContext);

    useEffect(() => {
        return () => {
            onIsLoading(false);
        };
    })
    return (
        <div style={{display:'flex', justifyContent:'center', height:'inherit', width:'inherit'}}>
            <ProgressiveImage
                src={selectedRiskImgUrl}
                placeholder={notFoundMapImage}
            >
                {(src) => <img src={src} style={{display: 'block', width: '95%'}} alt={src}/>}
            </ProgressiveImage>
        </div>
  );
}

export default MapImageContainer;
