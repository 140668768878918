import React from 'react';
import styled from 'styled-components';
import ProgressiveImage from 'react-progressive-graceful-image';

import './MyCustomedReactMagnifier.css';
import notFoundMapImage from '../image/notFoundMap.png';
import * as utils from '../utils/DateUtils';

const SingleMapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  padding-right: 2rem;
`;
const Title = styled.div`
  line-height: 1.5rem;
  font-size: 1rem;
  color: white;
  font-weight: bold;
  text-align: center;
  box-sizing: border-box;
  width: 13rem;
  border-radius: 0.2rem;
  background: #94d3ac;
`;
const MapImageWrapper = styled.div`
  border-radius: 1rem;
  background: #effcef;
  box-sizing: border-box;
  padding: 1rem;
  width: 600px;
  height: 600px;
  @media only screen and (min-height: 200px) {
    width: 110px;
    height: 110px;
  }
  @media only screen and (min-height: 300px) {
    width: 150px;
    height: 150px;
  }
  @media only screen and (min-height: 450px) {
    width: 360px;
    height: 360px;
  }
  @media only screen and (min-height: 700px) {
    width: 450px;
    height: 450px;
  }
  @media only screen and (min-height: 800px) {
    width: 550px;
    height: 550px;
  }
  @media only screen and (min-height: 990px) {
    width: 620px;
    height: 620px;
  }
`;

// const GrowthInfo = styled.div`
//   color: #655c56;
//   padding: 0.1rem;
//   margin-bottom: 0.3rem;
//   box-sizing: border-box;
//   border: solid 0.1rem #6eb882;
//   background-color: white;
//   text-align-last: center;
//   font-weight: bold;
//   font-size: 0.7rem;
//   width: 50%;
// `;

function SingleMapDataComponent(props) {
  const data = props.data;
  return (
    <SingleMapWrapper>
      <Title>
        {utils.getFormattedDate(data.date)} ({data.info})
      </Title>
      <MapImageWrapper>
        {/* {data.isGrowthStage === true ? null : (
          <GrowthInfo>
            {data.predictDay > 0 ? `${data.predictDay}일 후 생육단계를 이용` : `생육단계 이동 없음`}
          </GrowthInfo>
        )} */}

        <ProgressiveImage src={data.imageUrl} placeholder={notFoundMapImage}>
          {/* {(src) => (
            <Magnifier
              src={src}
              height={'100%'}
              mgShowOverflow={false}
              mgTouchOffsetX={-10}
              mgTouchOffsetY={-10}
              mgWidth={100}
              mgHeight={100}
              mgBorderWidth={0.01}
              zoomFactor={3}
              alt={notFoundMapImage}
            />
          )} */}
          {(src) => <img src={src} height="100%" alt={src}/>}
        </ProgressiveImage>
      </MapImageWrapper>
    </SingleMapWrapper>
  );
}

export default SingleMapDataComponent;
