import React, {useContext, useRef} from 'react';
import styled from 'styled-components';

import {SelectedOptionsContext} from '../SelectedOptionsContext';
import SingleMapDataComponent from './SingleMapDataComponent';
import {getMapImageUrl} from '../utils/EpinetApiUtils';
import {getOldMapImageUrl} from '../utils/OldEpinetApiUtils';

const Wrapper = styled.div`
  box-sizing: border-box;
  height: 70%;
  width: 100%;
  margin: 0;
`;

const MapWrapper = styled.div`
  height: 100%;
  font-size: 1rem;
  box-sizing: border-box;
  line-height: 1rem;
  @media screen and (max-width: 400px) {
    font-size: 0.8rem;
    line-height: 0.8rem;
  }
  display: flex;
  /* justify-content: space-between; */
  /* width: 100%; */
  /* white-space: nowrap; */
  overflow-x: scroll;
`;

function MapDataContainer(props) {
    const {selectedDate, selectedCrop, selectedMapType} = useContext(SelectedOptionsContext);

    const mapDataWrapper = useRef();
    const onClick = (e) => {
        mapDataWrapper.current.focus();
        mapDataWrapper.current.click();
    };

    const MapImageUrlsGenerator = (selectedDate, selectedCrop, selectedMapType, maxDay = 9) => {

        let mapData = [];

        if (selectedMapType.name.includes('frost')) {
            maxDay = 2;
        }

        for (let day = 0; day <= maxDay; day++) {
            let predictionTargetDate = new Date(selectedDate);
            predictionTargetDate.setDate(selectedDate.getDate() + day);
            mapData.push({
                id: day,
                date: predictionTargetDate,
                isGrowthStage: selectedMapType.name === 'gs' ? true : false,
                predictDay: day,
                info: day === 0 ? `당일` : `+${day}일`,
                imageUrl: getMapImageUrl(selectedDate, predictionTargetDate, selectedCrop, selectedMapType),
            });
        }
        return mapData;
    };

    const MapData = MapImageUrlsGenerator(selectedDate, selectedCrop, selectedMapType);

    return (
        <Wrapper>
            <MapWrapper ref={mapDataWrapper} onClick={onClick}>
                {MapData.map((item) => (
                    <SingleMapDataComponent key={item.id} data={item}/>
                ))}
            </MapWrapper>
        </Wrapper>
    );
}

export default MapDataContainer;
