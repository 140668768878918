import React, {useState, createContext} from 'react';

import {risks, crops, mapTypes} from './data/micro_climate_data.json';

export const SelectedOptionsContext = createContext();

const SelectedOptionsContextProvider = ({children}) => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedCrop, setSelectedCrop] = useState(crops[0]);
    const [selectedMapType, setSelectedMapType] = useState(
        crops[0].is_crop
            ? mapTypes.filter((type) => type.is_crop_dependent === true)[0]
            : mapTypes.filter((type) => type.is_crop_dependent === false)[0],
    );

    const [availableMapTypes, setAvailableMapTypes] = useState(
        crops[0].is_crop
            ? mapTypes.filter((type) => type.is_crop_dependent === true)
            : mapTypes.filter((type) => type.is_crop_dependent === false),
    );

    const [selectedRisk, setSelectedRisk] = useState(risks[0]);
    const [selectedRiskImgUrl, setSelectedRiskImgUrl] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const onChangeDate = (date) => {
        setSelectedDate(date);
    };

    const onSelectCrop = (cropId) => {
        const crop = crops[cropId];
        setSelectedCrop(crop);
        if (crop.is_crop) { // 작목이면, 작목에 있는 위험도만 가져오기 위해 filtering.
            let cropRisks = crop.risks;
            let filteredMapType = mapTypes.filter((o)=> o.name === 'growth' || cropRisks.includes(o.name));
            setSelectedMapType(filteredMapType[0]);
            setAvailableMapTypes(filteredMapType);
        } else {
            setSelectedMapType(mapTypes.filter((type) => type.is_crop_dependent === false)[0]);
            setAvailableMapTypes(mapTypes.filter((type) => type.is_crop_dependent === false));
        }
    };

    const onSelectRisk = (riskId) => {
        setSelectedRisk(risks[riskId]);
    }

    const onSelectRiskImgUrl = (riskImgUrl) => {
        setSelectedRiskImgUrl(riskImgUrl);
        setIsLoading(true)
    }


    const onSelectMapType = (mapTypeId) => {
        setSelectedMapType(mapTypes[mapTypeId]);
    };

    const onIsLoading = (isLoading) => {
        setIsLoading(isLoading);
    }

    const value = {
        selectedDate,
        selectedCrop,
        selectedRisk,
        selectedRiskImgUrl,
        selectedMapType,
        availableMapTypes,
        isLoading,
        onChangeDate,
        onSelectCrop,
        onSelectRisk,
        onSelectMapType,
        onSelectRiskImgUrl,
        onIsLoading,
    };

    return (
        <SelectedOptionsContext.Provider value={value}> {children} </SelectedOptionsContext.Provider>
    );
};

export default SelectedOptionsContextProvider;
