import React, { useContext } from 'react';

import { SelectedOptionsContext } from '../SelectedOptionsContext';
import SelectComponent from './SelectComponent';

function SelectMapTypeComponent(props) {
  const { selectedCrop, onSelectMapType, selectedMapType, availableMapTypes } = useContext(
    SelectedOptionsContext,
  );

  const onChangeMapType = (e) => {
    onSelectMapType(e.target.value);
  };

  return (
    <SelectComponent
      onChange={onChangeMapType}
      optionData={availableMapTypes}
      selectedOption={selectedMapType}
    />
  );
}

export default SelectMapTypeComponent;
